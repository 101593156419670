import React, { Component } from 'react';
import './css/asistencia.css';
import { DatePicker, Button, Alert, message } from 'antd';
import Webcam from "react-webcam";
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
/* import ModalContext from '../Context/ModalContext'; */

class Asistencia extends Component {

    /* static contextType = ModalContext; */

    webcamRef = React.createRef();
    passwordRef = React.createRef();


    awsConfig = {
        region: 'us-east-1',
        credentials: {
            accessKeyId: 'AKIAR7RLWHDOSQBQCSMS',
            secretAccessKey: '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',
        },
    };

    s3Client = new S3Client(this.awsConfig);

    state = {
        id_empleado: 0,
        nombre: '',
        asistencias: [],
        fecha: '',
        prox_id_es: 0,
        prox_tipo: '',
        fecha_hoy: '',
        selectValue: '',
        optionSelected: '',
        opencontext: false
    }

    constructor(props) {
        super(props);
        this.callThis = this.callThis.bind(this);
    }

    callThis = (e) => {
        this.setState({ selectValue: e.target.value });
    }

    componentDidMount() {
        const asistencias = this.state.asistencias;
        if (asistencias.length === 0) {
            /* var date = new Date().getDate(); //Current Date
            var month = new Date().getMonth() + 1; //Current Month
            var year = new Date().getFullYear(); //Current Year */

            var fecha = this.props.anio + '-' + this.props.mes + '-' + this.props.dia;

            const url = this.props.url_api + '/get_asistencia.php?almacen=' + this.props.almacen + '&fecha=' + fecha;
            fetch(url, { cache: "no-store" })
                .then(respuesta => respuesta.json())
                .then(resultado => this.setState({
                    fecha,
                    asistencias: resultado,
                    fecha_hoy: fecha
                }));
        }

    }

    isUserLogged = () => {
        if (this.state.id_empleado === 0) {
            return (
                <React.Fragment>
                    <b>Seleccione usuario:</b>
                    <form onSubmit={this.changeUserAsis}>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className="material-icons">person_outline</i>
                                </span>
                            </div>
                            <select onChange={this.callThis} className="form-control form-control-sm">
                                <option value="0,">Elija usuario a registrar asistencia...</option>
                                {this.mostrarEmpleados()}
                            </select>
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <i className='material-icons'>lock_outline</i>
                                </span>
                            </div>
                            <input ref={this.passwordRef} type="password" className="form-control" placeholder="Contraseña" required="required" />
                        </div>
                        <button type="submit" className="btn">ACCEDER</button>
                    </form>
                </React.Fragment>
            );
        } else {
            if (this.state.prox_id_es === 0) {
                var color = 'black';
            } else {
                var color = 'white';
            }

            if (this.state.prox_id_es === 1) var background_btn = '#259f62';
            if (this.state.prox_id_es === 2) var background_btn = '#e10119';
            if (this.state.prox_id_es === 3) var background_btn = '#fad260';
            if (this.state.prox_id_es === 4) var background_btn = '#084353';
            return (
                <React.Fragment>
                    {!(
                        this.state.id_empleado == 306 ||
                        this.state.id_empleado == 46 ||
                        this.state.id_empleado == 157 ||
                        this.state.id_empleado == 183 ||
                        this.state.id_empleado == 299 ||
                        this.state.id_empleado == 150 ||
                        this.state.id_empleado == 142 ||
                        this.state.id_empleado == 12 ||
                        this.state.id_empleado == 270 ||
                        this.state.id_empleado == 316
                    ) ?
                        <>
                            <Button
                                type="primary"
                                onClick={(e) => this.tomarFoto(e)}
                                style={{ width: '90%', height: '70px', margin: '5px 5% 5px', background: background_btn, color: color, border: '1px solid ' + background_btn, padding: '5px 0' }}
                                disabled={this.state.prox_id_es === 0 ? true : false}
                            >
                                Registrar <br />
                                <b style={{ fontSize: '23px' }}>{this.state.prox_tipo}</b>
                            </Button>
                            <Button
                                onClick={() => this.borrarDatosLog()}
                                style={{ width: '90%', height: '70px', margin: '5px 5%', background: 'none', color: '#595c63', border: '1px solid #595c63', padding: '5px 0' }}
                            >
                                ¿No eres tú?<br />
                                <b style={{ fontSize: '23px' }}>Volver</b>
                            </Button>
                        </>
                        :
                        <>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <i className="material-icons">format_list_numbered</i>
                                    </span>
                                </div>
                                <select onChange={(e) => this.setState({ optionSelected: e.target.value })} className="form-control form-control-sm">
                                    <option value="0">Elija tipo de registro...</option>
                                    <option value="1">Entrada</option>
                                    <option value="3">Salida a comer</option>
                                    <option value="4">Entrada de comer</option>
                                    <option value="2">Salida</option>
                                </select>
                            </div>
                            <Button
                                type="primary"
                                onClick={(e) => this.tomarFotoSelection(e)}
                                style={{ width: '90%', height: '70px', margin: '5px 5% 5px', background: background_btn, color: color, border: '1px solid ' + background_btn, padding: '5px 0' }}
                                disabled={this.state.prox_id_es === 0 ? true : false}
                            >
                                <b style={{ fontSize: '23px' }}>Registrar</b>
                            </Button>
                            <Button
                                onClick={() => this.borrarDatosLog()}
                                style={{ width: '90%', height: '70px', margin: '5px 5%', background: 'none', color: '#595c63', border: '1px solid #595c63', padding: '5px 0' }}
                            >
                                ¿No eres tú?<br />
                                <b style={{ fontSize: '23px' }}>Volver</b>
                            </Button>
                        </>
                    }


                </React.Fragment>
            );

        }
    }

    mostrarEmpleados = () => {
        if (this.props.empleados) {
            const empleados = this.props.empleados;
            if (empleados.length === 0) {
                return (
                    <React.Fragment>
                        <option value="">Sin opciones</option>
                    </React.Fragment>
                );
            }
            return (
                <React.Fragment>
                    {empleados.map(empleado => (
                        <option value={empleado.id + "," + empleado.nombre + " " + empleado.apellido} key={empleado.id}>{empleado.nombre + " " + empleado.apellido}</option>
                    ))}
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <h5>Sin resultados</h5>
                </React.Fragment>
            );
        }
    }

    changeUserAsis = (e) => {
        e.preventDefault();

        const password = this.passwordRef.current.value;
        var res = this.state.selectValue.split(",");
        var id_empleado = res[0];
        var nombre = res[1];
        const url = this.props.url_api + '/get_asistenciaUser.php?user=' + id_empleado + '&fecha=' + this.state.fecha_hoy + '&password=' + password;

        message.loading('Cargando datos de asistencia..', 0.5);
        fetch(url, { cache: "no-store" })
            .then(respuesta => respuesta.json())
            .then(resultado => this.guardarDatosAsis(resultado.id_es, resultado.tipo_asis, id_empleado, nombre, resultado.clase));
    }

    guardarDatosAsis = (id_es, tipo_asis, id_empleado, nombre, clase) => {
        if (clase === 'error') {
            message.error('Contraseña incorrecta', 3);
        } else {
            this.setState({
                prox_id_es: id_es,
                prox_tipo: tipo_asis,
                id_empleado,
                nombre
            })
        }
    }

    borrarDatosLog = () => {
        this.setState({
            id_empleado: 0,
            nombre: ''
        })
    }

    base64ToBlob = (base64String, contentType) => {
        const byteCharacters = atob(base64String);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: contentType });
    };

    getFechaParaFoto = () => {
        const date = new Date();
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}-${month}-${year}_${hours}${minutes}${seconds}`;
    };

    tomarFoto = (event) => {

        /* if (this.props.almacen !== 26 && this.props.almacen !== 49) {
            const { isModalOpen, ModalOpen, ModalClose } = this.context;
            if (isModalOpen) {
                ModalClose();
            } else {
                ModalOpen(this.props.almacen);
            }
        } */

        if (this.state.isSubmitting) return;
    
        this.setState({ isSubmitting: true });

        const imageSrc = this.webcamRef.current.getScreenshot();

        if (!imageSrc) {
            message.error('Error al tomar la foto. Intente nuevamente', 3);
            this.setState({ isSubmitting: false });
            return;
        }

        const id_es = this.state.prox_id_es;

        message.loading('Enviando datos de asistencia..', 1);

        const url = this.props.url_api + '/insertar_webp.php';

        const hora = this.getHora();

        fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                almacen: this.props.almacen,
                token: this.props.token,
                usuario: this.state.id_empleado,
                id_es: id_es,
                img: imageSrc,
                fecha: this.state.fecha_hoy,
                admin: 0,
                hora: hora
            })
        })
            .then(respuesta => respuesta.json())
            .then(resultado => {

                const base64Cleaned = imageSrc.replace(/^data:image\/(png|jpeg|webp);base64,/, '');
                const contentType = imageSrc.match(/^data:(image\/(png|jpeg|webp));base64,/)[1];
                const blob = this.base64ToBlob(base64Cleaned, contentType);
                const fileName = `checkin-fotos/${this.state.fecha_hoy}-${hora}_${this.state.id_empleado}_${id_es}.webp`;

                const params = {
                    Bucket: 'harescloud',
                    Key: fileName,
                    Body: blob,
                    ContentEncoding: 'base64',
                    ContentType: 'image/webp',
                };

                try {
                    this.s3Client.send(new PutObjectCommand(params)).then(() => {
                        this.setState({
                            asistencias: resultado.asistencias,
                            id_empleado: 0,
                            nombre: '',
                            isSubmitting: false
                        })
                    })
                } catch (error) {
                    console.error('Error al subir el archivo:', error);
                    this.setState({ isSubmitting: false });
                }
            });
    }

    tomarFotoSelection = (event) => {

        /* if (this.props.almacen !== 26 && this.props.almacen !== 49) {
            const { isModalOpen, ModalOpen, ModalClose } = this.context;
            if (isModalOpen) {
                ModalClose();
            } else {
                ModalOpen(this.props.almacen);
            }
        } */

        if (this.state.optionSelected == 0) return alert("Por favor elija un tipo de registro");

        message.loading('Enviando datos de asistencia..', 1);

        if (this.state.isSubmitting) return;
    
        this.setState({ isSubmitting: true });

        const imageSrc = this.webcamRef.current.getScreenshot();

        if (!imageSrc) {
            message.error('Error al tomar la foto. Intente nuevamente', 3);
            this.setState({ isSubmitting: false });
            return;
        }
        const url = this.props.url_api + '/insertar_webp.php';

        const hora = this.getHora();

        fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                almacen: this.props.almacen,
                token: this.props.token,
                usuario: this.state.id_empleado,
                id_es: this.state.optionSelected,
                fecha: this.state.fecha_hoy,
                admin: 0,
                hora: hora
            })
        })
            .then(respuesta => respuesta.json())
            .then(resultado => {

                const base64Cleaned = imageSrc.replace(/^data:image\/(png|jpeg|webp);base64,/, '');
                const contentType = imageSrc.match(/^data:(image\/(png|jpeg|webp));base64,/)[1];
                const blob = this.base64ToBlob(base64Cleaned, contentType);
                const fileName = `checkin-fotos/${this.state.fecha_hoy}-${hora}_${this.state.id_empleado}_${this.state.optionSelected}.webp`;

                const params = {
                    Bucket: 'harescloud',
                    Key: fileName,
                    Body: blob,
                    ContentEncoding: 'base64',
                    ContentType: 'image/webp',
                };

                try {
                    this.s3Client.send(new PutObjectCommand(params)).then(() => {
                        this.setState({
                            asistencias: resultado.asistencias,
                            id_empleado: 0,
                            nombre: '',
                            isSubmitting: false
                        })
                    })
                } catch (error) {
                    console.error('Error al subir el archivo:', error);
                    this.setState({ isSubmitting: false });
                }
            });
    }

    getHora = () => {
        var horas = this.props.horas;
        var minutos = this.props.minutos;
        var segundos = this.props.segundos;
        if (this.props.mt === 'PM') {
            horas = horas + 12;
        }
        if (horas < 10) {
            horas = "0" + horas;
        }
        if (minutos < 10) {
            minutos = "0" + minutos;
        }
        if (segundos < 10) {
            segundos = "0" + segundos;
        }
        const horafinal = horas + ":" + minutos + ":" + segundos;
        return (horafinal)
    }

    mostrarAsistencias = () => {
        const asistencias = this.state.asistencias;
        if (asistencias.length !== 0) {
            return (
                <React.Fragment>
                    {asistencias.map(asis => (
                        <div id="persona" className={asis.clase} key={asis.id}>
                            <div className="img-container">
                                <img src={"https://dqek15ob4rw2.cloudfront.net/" + asis.url_img} alt={asis.id} />
                                <div className="datos">
                                    <h3>{asis.nombre}</h3>
                                    <h1 className="hora">{asis.hora} Hrs</h1>
                                </div>
                            </div>
                            <h6><b>{asis.tipo_asis}</b></h6>
                        </div>
                    ))}
                </React.Fragment>
            );
        } else {
            return (
                <Alert
                    message="No se ha encontrado registros de asistencias"
                    description="No hubo resultados con la fecha o almacen indicados."
                    type="info"
                    showIcon
                />
            );
        }
    }

    handleDatePickerChange = (dateString) => {
        var date = new Date(dateString._d).getDate(); //Current Date
        var month = new Date(dateString._d).getMonth() + 1; //Current Month
        var year = new Date(dateString._d).getFullYear(); //Current Year

        var fecha = year + '-' + month + '-' + date;
        message.loading('Obteniendo registros de asistencia..', 1);
        const url = this.props.url_api + '/get_asistencia.php?almacen=' + this.props.almacen + '&token=' + this.props.token + '&fecha=' + fecha;
        fetch(url, { cache: "no-store" })
            .then(respuesta => respuesta.json())
            .then(resultado => this.setState({
                fecha,
                asistencias: resultado
            }));
    }

    render() {
        /* const { ModalOpen } = this.context; */
        return (
            <React.Fragment>

                <div className="container" style={{ marginTop: '100px' }}>
                    <div className="row">
                        <div className="col-9 today_attendance">
                            <div className="cont_today_at">
                                <div className="row sin-margen">
                                    <div className="col-sm-8">
                                        <h2>Asistencia de {this.state.fecha === this.state.fecha_hoy ? "hoy" : this.state.fecha}</h2>
                                    </div>
                                    {/* <div
                                        className="col-sm-4 content-abrir-avisos"
                                    >
                                        {this.props.almacen !== 26 && this.props.almacen !== 49 ?
                                            <Button
                                                type='primary'
                                                style={{
                                                    backgroundColor: '#4746bd',
                                                    border: 'none',
                                                    borderRadius: '4px'
                                                }}
                                                onClick={() => { ModalOpen(this.props.almacen) }}
                                            >📫 Avisos del día</Button>
                                            : null}
                                    </div> */}
                                    <div className="col-sm-4">
                                        <DatePicker size='large' placeholder="Elija otra fecha..." onChange={this.handleDatePickerChange} />
                                    </div>
                                </div>

                                <hr />
                                {this.mostrarAsistencias()}
                            </div>
                        </div>
                        <div className="col-3 nuevaAsistencia">
                            {this.state.nombre === '' ? <h2><span>Registrar</span>Nueva asistencia</h2> : <h2><span>¡Bienvenido,</span> {this.state.nombre}!</h2>}<br />
                            <Webcam
                                audio={false}
                                style={{ width: '90%', margin: '0 5%', borderRadius: '8px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)' }}
                                ref={this.webcamRef}
                                screenshotFormat="image/png"
                                screenshotQuality='0.7'
                                videoConstraints={{
                                    width: 218,
                                    height: 165,
                                    facingMode: "user"
                                }}
                            />
                            <div className="conSelect" style={{ marginTop: '20px' }}>
                                {this.isUserLogged()}
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

// Asistencia.contextType = ModalContext;

export default Asistencia;